<template>
  <div id="app">
    <keep-alive v-if="false">
      <router-view/>
    </keep-alive>
    <router-view v-else/>
  </div>
</template>

<script>
import { isMobile } from '@/config/common.js'
export default {
  mounted() {
    isMobile(this)
  }
}
</script>

<style lang="less">
</style>
