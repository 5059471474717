export default [{
    path: '*',
    name: 'home',
    meta: {
      title: '易借速贷官网-现金分期贷款平台'
    },
    component: () => import('@/pages/pc/home.vue')
  },
  {
    path: '/productdownload',
    name: 'productdownload',
    meta: {
      title: '旗下产品'
    },
    component: () => import('@/pages/pc/productDownload.vue')
  },
  {
    path: '/newsinfo',
    name: 'newsinfo',
    meta: {
      title: '新闻资讯'
    },
    component: () => import('@/pages/pc/newsInfo.vue')
  },
  {
    path: '/newsDetails',
    name: 'newsDetails',
    meta: {
      title: ''
    },
    component: () => import('@/pages/pc/newsDetails.vue')
  },
  {
    path: '/helpcenter',
    name: 'helpcenter',
    meta: {
      title: '帮助中心'
    },
    component: () => import('@/pages/pc/helpCenter.vue')
  },
  {
    path: '/about',
    name: 'about',
    meta: {
      title: '关于我们'
    },
    component: () => import('@/pages/pc/about.vue')
  },
  {
    path: '/contactus',
    name: 'contactus',
    meta: {
      title: '联系我们'
    },
    component: () => import('@/pages/pc/contactUs.vue')
  }
]