import Vue from 'vue'
import Router from 'vue-router'
import pcRouter from './pcRouter'
import mobileRouter from './mobileRouter'
Vue.use(Router)

let routes = new Set([...pcRouter, ...mobileRouter])
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    if (!to.query.type && !to.hash) {
      return {x:0,y:0}
    }
  }
})
router.afterEach((to) => {
  document.title = to.meta.title
}) 

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router;

