export const handleElementScroll = (el) => {
  try {
    if (el) {
      el.scrollIntoView()
    }
  } catch (error) {
    console.log(error);
  }
}
export const isMobile = (_this) => {
  let urlSystem = 'pc'
  let nowSystem = 'pc'
  if ((/\/m/g).test(window.location.href)) {
      urlSystem = 'mobile'
  }
  if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))){
      nowSystem = 'mobile'
  }
  if(urlSystem !== nowSystem) {
    if (nowSystem === 'mobile') {
      _this.$router.replace({path: '/m/home'})
    } else {
      _this.$router.replace({path: '/'})
    }
  }
}

// 判断手机系统，1为android，2为ios
export const getOsFrom = () => {
  try {
    let u = window.navigator.userAgent || 'Android'
    let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 // android终端
    let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // ios终端
    if (isAndroid) { // 返回1是android
      return 'and'
    }
    if (isiOS) { // 返回2是ios
      return 'ios'
    }
    return 'and'
  } catch (e) {
    return 'and'
  }
}