import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'animate.css'
import '@/assets/styles/reset.css'
import '@/assets/styles/common.less'
import '@/assets/styles/animation.less'

import {
  handleElementScroll
} from '@/config/common.js'
Vue.use(ElementUI)
Vue.config.productionTip = false

import {
  Message
} from 'element-ui'

Vue.prototype.$handleElementScroll = handleElementScroll
Vue.prototype.$message = Message

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')