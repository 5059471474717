export default [
  {
    path: '/m/home',
    name: 'home',
    meta: { title: '易借速贷官网-现金分期贷款平台' },
    component: () => import('@/pages/mobile/home.vue')
  },
  {
    path: '/m/contactus',
    name: 'contactus',
    meta: { title: '联系我们' },
    component: () => import('@/pages/mobile/contactUs.vue')
  }, 
  {
    path: '/m/helpcenter',
    name: 'helpcenter',
    meta: { title: '帮助中心' },
    component: () => import('@/pages/mobile/helpCenter.vue')
  }
]